// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GenericChart_container__\\+NBGc{
  display: flex;
  padding-left: 100px; /* Usamos un porcentaje en lugar de un valor fijo */
  padding-right: 100px; /* Usamos un porcentaje en lugar de un valor fijo */
  padding-top: 75px;
  width: 100%;
}

.GenericChart_containerGuest__RsO9h{
  display: flex;
  width: 100%;
}

/* Estilos para pantallas más pequeñas (por ejemplo, hasta 1250px) */
@media (max-width: 1250px) {
  .GenericChart_container__\\+NBGc{
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 40px;
  }
}

@media (max-width: 1400px) {
  .GenericChart_container__\\+NBGc{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
  }
}

@media (max-width: 950px) {
  .GenericChart_container__\\+NBGc{
    flex-direction: column;
    overflow-y: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/generic-chart/GenericChart.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB,EAAE,mDAAmD;EACxE,oBAAoB,EAAE,mDAAmD;EACzE,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,WAAW;AACb;;AAEA,oEAAoE;AACpE;EACE;IACE,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,sBAAsB;IACtB,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;EACnB;AACF","sourcesContent":[".container{\n  display: flex;\n  padding-left: 100px; /* Usamos un porcentaje en lugar de un valor fijo */\n  padding-right: 100px; /* Usamos un porcentaje en lugar de un valor fijo */\n  padding-top: 75px;\n  width: 100%;\n}\n\n.containerGuest{\n  display: flex;\n  width: 100%;\n}\n\n/* Estilos para pantallas más pequeñas (por ejemplo, hasta 1250px) */\n@media (max-width: 1250px) {\n  .container{\n    padding-left: 80px;\n    padding-right: 80px;\n    padding-top: 40px;\n  }\n}\n\n@media (max-width: 1400px) {\n  .container{\n    padding-left: 20px;\n    padding-right: 20px;\n    padding-top: 40px;\n  }\n}\n\n@media (max-width: 950px) {\n  .container{\n    flex-direction: column;\n    overflow-y: auto;\n    padding-left: 20px;\n    padding-right: 20px;\n    padding-top: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `GenericChart_container__+NBGc`,
	"containerGuest": `GenericChart_containerGuest__RsO9h`
};
export default ___CSS_LOADER_EXPORT___;
