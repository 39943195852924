// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InvitationCode_modalBox__mdrln {
  display: flex;
  flex-direction: row;
  width: 500px;
  justify-content: space-between;
}
.InvitationCode_modalBox__mdrln .InvitationCode_text__Derkc {
  padding-left: 10px;
}
.InvitationCode_modalBox__mdrln .InvitationCode_boxicon__NsmSF {
  cursor: pointer;
}
.InvitationCode_modalBox__mdrln .InvitationCode_boxicon__NsmSF .InvitationCode_icon__Jsru4 {
  color: grey;
  align-self: center;
}

.InvitationCode_modalBox2__DtQWs {
  padding-left: 14px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}
.InvitationCode_modalBox2__DtQWs .InvitationCode_expire__ySGA8 {
  margin-top: 1%;
}
.InvitationCode_modalBox2__DtQWs .InvitationCode_continue__knL4S {
  margin-top: 2%;
}
.InvitationCode_modalBox2__DtQWs .InvitationCode_link__1P8nN {
  align-self: center;
  margin-top: 1%;
}

.InvitationCode_buttonBox__RfN0h {
  display: flex;
  flex-direction: column;
}
.InvitationCode_buttonBox__RfN0h .InvitationCode_button__ugVCl {
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background-color: var(--GREEN-ARIA);
  color: white;
  border-radius: 24px;
  gap: 8px;
  width: 220px;
  height: 40px;
  font-size: 16px;
  font-family: "GothamBold", sans-serif;
  align-self: center;
  margin-top: 10%;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/features/invitationCode/InvitationCode.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,8BAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;EACE,eAAA;AAAJ;AACI;EACE,WAAA;EACA,kBAAA;AACN;;AAIA;EACE,kBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;AADF;AAGE;EACE,cAAA;AADJ;AAIE;EACE,cAAA;AAFJ;AAKE;EACE,kBAAA;EACA,cAAA;AAHJ;;AAOA;EACE,aAAA;EACA,sBAAA;AAJF;AAME;EACE,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,eAAA;EACA,mCAAA;EACA,YAAA;EACA,mBAAA;EACA,QAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,qCAAA;EACA,kBAAA;EACA,eAAA;EACA,mBAAA;AAJJ","sourcesContent":[".modalBox {\n  display: flex;\n  flex-direction: row;\n  width: 500px;\n  justify-content: space-between;\n\n  .text {\n    padding-left: 10px;\n  }\n\n  .boxicon {\n    cursor: pointer;\n    .icon {\n      color: grey;\n      align-self: center;\n    }\n  }\n}\n\n.modalBox2 {\n  padding-left: 14px;\n  padding-top: 10px;\n  display: flex;\n  flex-direction: column;\n\n  .expire {\n    margin-top: 1%;\n  }\n\n  .continue {\n    margin-top: 2%;\n  }\n\n  .link {\n    align-self: center;\n    margin-top: 1%;\n  }\n}\n\n.buttonBox {\n  display: flex;\n  flex-direction: column;\n\n  .button {\n    text-align: center;\n    display: flex;\n    justify-content: center;\n    cursor: pointer;\n    background-color: var(--GREEN-ARIA);\n    color: white;\n    border-radius: 24px;\n    gap: 8px;\n    width: 220px;\n    height: 40px;\n    font-size: 16px;\n    font-family: 'GothamBold', sans-serif;\n    align-self: center;\n    margin-top: 10%;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBox": `InvitationCode_modalBox__mdrln`,
	"text": `InvitationCode_text__Derkc`,
	"boxicon": `InvitationCode_boxicon__NsmSF`,
	"icon": `InvitationCode_icon__Jsru4`,
	"modalBox2": `InvitationCode_modalBox2__DtQWs`,
	"expire": `InvitationCode_expire__ySGA8`,
	"continue": `InvitationCode_continue__knL4S`,
	"link": `InvitationCode_link__1P8nN`,
	"buttonBox": `InvitationCode_buttonBox__RfN0h`,
	"button": `InvitationCode_button__ugVCl`
};
export default ___CSS_LOADER_EXPORT___;
