// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PhoneCodeSelector_inputInvite__Ewfxs {
  width: 100%;
  padding-left: 13px;
  border: solid 1px var(--GREEN-ARIA);
  height: 45px;
  border-radius: 24px;
  font-family: Montserrat, sans-serif;
  color: var(--TEXT-GRAY-DARK);
  font-size: 1.5rem;
}
.PhoneCodeSelector_inputInvite__Ewfxs:focus {
  outline: none;
  border: solid 2px var(--GREEN-ARIA);
}

.PhoneCodeSelector_selectCountry__1y8DN {
  outline: none;
  width: 80px;
  margin-right: 20px;
  border: none;
}
.PhoneCodeSelector_selectCountry__1y8DN div {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px var(--GREEN-ARIA);
}
.PhoneCodeSelector_selectCountry__1y8DN div :first-of-type, .PhoneCodeSelector_selectCountry__1y8DN div :last-of-type {
  border: none;
}
.PhoneCodeSelector_selectCountry__1y8DN div .PhoneCodeSelector_textSelect__uTcLx {
  font-family: Montserrat, sans-serif;
  font-size: 8px;
  color: var(--TEXT-GRAY-DARK);
  margin-left: 5px;
}
.PhoneCodeSelector_selectCountry__1y8DN div .PhoneCodeSelector_textSelect__uTcLx span {
  font-size: 10px;
}
.PhoneCodeSelector_selectCountry__1y8DN div .PhoneCodeSelector_itemIcon__qvKjk {
  min-width: 0;
}

.PhoneCodeSelector_containerTextField__4vTS- {
  width: 60%;
}

.PhoneCodeSelector_menuItemSelect__EEvT6 {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/phone-code-selector/PhoneCodeSelector.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,mCAAA;EACA,YAAA;EACA,mBAAA;EACA,mCAAA;EACA,4BAAA;EACA,iBAAA;AACF;AACE;EACE,aAAA;EACA,mCAAA;AACJ;;AAIA;EACE,aAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;AADF;AAGE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mCAAA;AADJ;AAGI;EACE,YAAA;AADN;AAII;EACE,mCAAA;EACA,cAAA;EACA,4BAAA;EACA,gBAAA;AAFN;AAIM;EACE,eAAA;AAFR;AAMI;EACE,YAAA;AAJN;;AASA;EACE,UAAA;AANF;;AASA;EACE,aAAA;AANF","sourcesContent":[".inputInvite {\n  width: 100%;\n  padding-left: 13px;\n  border: solid 1px var(--GREEN-ARIA);\n  height: 45px;\n  border-radius: 24px;\n  font-family: Montserrat, sans-serif;\n  color: var(--TEXT-GRAY-DARK);\n  font-size: 1.5rem;\n\n  &:focus {\n    outline: none;\n    border: solid 2px var(--GREEN-ARIA);\n  }\n\n}\n\n.selectCountry {\n  outline: none;\n  width: 80px;\n  margin-right: 20px;\n  border:none;\n\n  div {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border: solid 1px var(--GREEN-ARIA);\n\n    :first-of-type, :last-of-type {\n      border:none\n    }\n\n    .textSelect {\n      font-family: Montserrat, sans-serif;\n      font-size: 8px;\n      color: var(--TEXT-GRAY-DARK);\n      margin-left: 5px;\n\n      span {\n        font-size: 10px;\n      }\n    }\n\n    .itemIcon {\n      min-width: 0;\n    }\n  }\n}\n\n.containerTextField {\n  width: 60%;\n}\n\n.menuItemSelect {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputInvite": `PhoneCodeSelector_inputInvite__Ewfxs`,
	"selectCountry": `PhoneCodeSelector_selectCountry__1y8DN`,
	"textSelect": `PhoneCodeSelector_textSelect__uTcLx`,
	"itemIcon": `PhoneCodeSelector_itemIcon__qvKjk`,
	"containerTextField": `PhoneCodeSelector_containerTextField__4vTS-`,
	"menuItemSelect": `PhoneCodeSelector_menuItemSelect__EEvT6`
};
export default ___CSS_LOADER_EXPORT___;
