import { File } from './File'
import { v4 as uuidv4 } from 'uuid'
import { DocType } from '../enum/FileEnums'

export interface FileDTO {
  id?: string
  name: string
  base64: string
  type: string
  docType: DocType
  ownerID: string
  date: Date
  extension: string
  ownerRole: string | undefined
  content: string | undefined
}

export function emptyFileDTO(
  base64: string,
  type: string,
  docType: DocType,
  ownerID: string,
  extension: string,
): FileDTO {
  return {
    id: uuidv4(),
    name: '',
    base64,
    type,
    docType,
    ownerID,
    date: new Date(),
    extension,
    ownerRole: undefined,
    content: undefined,
  }
}

export function fromModel(f: File): FileDTO {
  return {
    id: f.id,
    name: f.name,
    base64: f.base64,
    type: f.type,
    docType: f.docType,
    ownerID: f.ownerID,
    date: f.date,
    extension: f.extension,
    ownerRole: f.ownerRole,
    content: f.content,
  }
}

export function toModel(d: FileDTO): File {
  return new File(d)
}
