import { ChatbotSessionDTO } from './ChatbotSessionDTO'

export class ChatbotSession {
  private readonly _session_id: string | undefined
  private readonly _response: string

  constructor(p: ChatbotSessionDTO) {
    this._session_id = p.session_id
    this._response = p.response
  }

  get sessionID(): string | undefined {
    return this._session_id
  }

  get response(): string {
    return this._response
  }
}
