// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footerContainer__n-gFQ {
  background-color: var(--GREEN-ARIA);
  text-align: center;
  align-self: flex-end;
  margin-top: auto;
  width: 100%;
  padding: 13px 74px 13px 0 !important;
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
}
.Footer_footerContainer__n-gFQ .Footer_contentTexts__MhGCf {
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
}
.Footer_footerContainer__n-gFQ .Footer_footerText__wQtfa {
  cursor: pointer;
  color: #FFF;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-decoration: none !important;
}
.Footer_footerContainer__n-gFQ .Footer_footerText__wQtfa:hover {
  background-color: transparent;
  font-size: 15px;
  transition: 0.5s;
  text-decoration: none;
}
.Footer_footerContainer__n-gFQ .Footer_divider__RvwcS {
  width: 1.5px;
  height: 16px;
  background-color: #FFF !important;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.module.scss"],"names":[],"mappings":"AAGA;EACI,mCAAA;EACA,kBAAA;EACA,oBAAA;EACA,gBAAA;EACA,WAAA;EACA,oCAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,qBAAA;AAFJ;AAII;EACI,6BAAA;EACA,aAAA;EACA,yBAAA;EACA,SAAA;AAFR;AAII;EACI,eAAA;EACA,WAvBM;EAwBN,qCAzBM;EA0BN,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gCAAA;AAFR;AAIQ;EACI,6BAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;AAFZ;AAMI;EACI,YAAA;EACA,YAAA;EACA,iCAAA;AAJR","sourcesContent":["$font-family: 'Montserrat', sans-serif;\n$color-white: #FFF;\n\n.footerContainer{\n    background-color: var(--GREEN-ARIA);\n    text-align: center;\n    align-self: flex-end;\n    margin-top: auto;\n    width: 100%;\n    padding: 13px 74px 13px 0 !important;\n    height: auto;\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    text-decoration: none;\n\n    .contentTexts{\n        background-color: transparent;\n        display: flex;\n        justify-content: flex-end;\n        gap: 24px;\n    }\n    .footerText{\n        cursor: pointer;\n        color: $color-white;\n        font-family: $font-family;\n        font-size: 13px;\n        font-style: normal;\n        font-weight: 900;\n        line-height: normal;\n        text-decoration: none !important;\n\n        &:hover{\n            background-color: transparent;\n            font-size: 15px;\n            transition: 0.5s;\n            text-decoration: none;\n        }\n    }\n\n    .divider{\n        width: 1.5px;\n        height: 16px;\n        background-color: $color-white !important;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerContainer": `Footer_footerContainer__n-gFQ`,
	"contentTexts": `Footer_contentTexts__MhGCf`,
	"footerText": `Footer_footerText__wQtfa`,
	"divider": `Footer_divider__RvwcS`
};
export default ___CSS_LOADER_EXPORT___;
