import styles from './PrivacyCookies.module.scss'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

export const PrivacyCookies = () => {
  const { t } = useTranslation()

  return (
    <Box className={styles.privacyContainer}>
      <Box className={styles.privacyContainer2}>
        <p className={styles.privacyTitle}>{t('privacyCookies')}</p>
        <Box className={styles.linedashboard0}>
          <Box className={styles.linedashboard} />
        </Box>
        <Box className={styles.textbox}>
          <Box className={styles.lastUpdate}>
            <p className={styles.title}>{t('termsOfUse')}</p>
            <p className={styles.title}>{t('useTermsLastUpdate')}</p>
          </Box>
          <pre className={styles.description}>{t('useTermsText')}</pre>
          <Box className={styles.lastUpdate}>
            <p className={styles.title}>{t('privacyPolicy')}</p>
            <p className={styles.title}>{t('privacyPolicyLastUpdate')}</p>
          </Box>
          <pre className={styles.description}>{t('privacyPolicyText')}</pre>
          <Box className={styles.lastUpdate}>
            <p className={styles.title}>{t('cookiesPolicy')}</p>
            <p className={styles.title}>{t('cookiesPolicyLastUpdate')}</p>
          </Box>
          <pre className={styles.description}>{t('cookiesPolicyText')}</pre>
        </Box>
      </Box>
    </Box>
  )
}
