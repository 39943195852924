// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppTable_searchContainer__dfp-r {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

/* Estilos para pantallas más pequeñas */
@media (max-width: 1300px) {
  .AppTable_responsiveTable__Vgfhq {
    width: 100%;
    overflow-x: auto;
  }
  .AppTable_responsiveTable__Vgfhq th,
  .AppTable_responsiveTable__Vgfhq td {
    white-space: nowrap; /* Evita que el texto se ajuste automáticamente */
  }
  /* Ajusta los estilos de las filas y celdas según sea necesario */
  .AppTable_responsiveTable__Vgfhq th {
    /* Estilos para encabezados en pantallas pequeñas */
  }
  .AppTable_responsiveTable__Vgfhq td {
    /* Estilos para celdas en pantallas pequeñas */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/table/AppTable.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,eAAe;AACjB;;AAEA,wCAAwC;AACxC;EACE;IACE,WAAW;IACX,gBAAgB;EAClB;EACA;;IAEE,mBAAmB,EAAE,iDAAiD;EACxE;EACA,iEAAiE;EACjE;IACE,mDAAmD;EACrD;EACA;IACE,8CAA8C;EAChD;AACF","sourcesContent":[".searchContainer {\n  display: flex;\n  justify-content: flex-end;\n  flex-wrap: wrap;\n}\n\n/* Estilos para pantallas más pequeñas */\n@media (max-width: 1300px) {\n  .responsiveTable {\n    width: 100%;\n    overflow-x: auto;\n  }\n  .responsiveTable th,\n  .responsiveTable td {\n    white-space: nowrap; /* Evita que el texto se ajuste automáticamente */\n  }\n  /* Ajusta los estilos de las filas y celdas según sea necesario */\n  .responsiveTable th {\n    /* Estilos para encabezados en pantallas pequeñas */\n  }\n  .responsiveTable td {\n    /* Estilos para celdas en pantallas pequeñas */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchContainer": `AppTable_searchContainer__dfp-r`,
	"responsiveTable": `AppTable_responsiveTable__Vgfhq`
};
export default ___CSS_LOADER_EXPORT___;
