import {
  ChangePassword,
  RegisterUser,
  SendSMS,
  User,
  UserChatbotSession,
  UserDTO,
  UserQuery,
} from '../models/User'
import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { IStatusService } from '../../../common/status/StatusService'
import { IUserApi } from '../api/UserApi'
import { ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'

type Props = {
  apiKey: symbol
}

export interface IUserService extends IInit {
  getByID(id: string): Observable<User | undefined>

  getFilteredList(q: Query<UserQuery>): Observable<ItemList<User>>

  add(e: RegisterUser): Observable<User | string | undefined>

  update(e: RegisterUser): Observable<RegisterUser | undefined>

  updateChatbotSession(
    e: UserChatbotSession,
  ): Observable<UserChatbotSession | undefined>

  updateLanguage(e: UserDTO): Observable<UserDTO | undefined>

  delete(id: string): Observable<boolean>

  changePassword(cp: ChangePassword): Observable<any>

  sendInvitationCode(
    phone: string,
    emailFrom: string,
  ): Observable<SendSMS | undefined>

  addInvitationCode(code: string, id: string): Observable<any>
  getUsersInvitationsByUserID(q: Query<UserQuery>): Observable<ItemList<User>>
}

export class UserService implements IUserService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IUserApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IUserApi>(this._apiKey)
    this._statusService =
      this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: RegisterUser): Observable<User | string | undefined> {
    return this._api.add(e)
  }

  getByID(id: string): Observable<User | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<UserQuery>): Observable<ItemList<User>> {
    return this._api.getFilteredList(q).pipe()
  }

  update(e: RegisterUser): Observable<RegisterUser | undefined> {
    return this._api.update(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  changePassword(e: ChangePassword): Observable<any> {
    return this._api.changePassword(e)
  }

  sendInvitationCode(
    phone: string,
    emailFrom: string,
  ): Observable<SendSMS | undefined> {
    return this._api.sendInvitationCode(phone, emailFrom)
  }

  addInvitationCode(code: string, id: string): Observable<any> {
    return this._api.addInvitationCode(code, id)
  }

  getUsersInvitationsByUserID(q: Query<UserQuery>): Observable<ItemList<User>> {
    return this._api.getUsersInvitationsByUserID(q)
  }

  updateChatbotSession(
    e: UserChatbotSession,
  ): Observable<UserChatbotSession | undefined> {
    return this._api.updateChatbotSession(e)
  }

  updateLanguage(e: UserDTO): Observable<UserDTO | undefined> {
    return this._api.updateLanguage(e)
  }
}
