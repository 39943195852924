// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GenericHeader_title__Wd3Lv {
  font-family: Gotham Rounded, sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--BLUE-ARIA);
}`, "",{"version":3,"sources":["webpack://./src/components/header/GenericHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,uCACQ;EAER,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;AADF","sourcesContent":[".title {\n  font-family:\n          Gotham Rounded,\n          sans-serif;\n  font-size: 42px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  color: var(--BLUE-ARIA);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `GenericHeader_title__Wd3Lv`
};
export default ___CSS_LOADER_EXPORT___;
