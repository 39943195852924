import { ChatbotQuestion } from './ChatbotQuestion'

export interface ChatbotQuestionDTO {
  session_id?: string
  query: string
  folder: string
  locale: string
}

export function emptyFileDTO(): ChatbotQuestionDTO {
  return {
    session_id: '',
    query: '',
    folder: 'default',
    locale: '',
  }
}

export function fromModel(f: ChatbotQuestion): ChatbotQuestionDTO {
  return {
    session_id: f.sessionID,
    query: f.query,
    folder: f.folder,
    locale: f.locale,
  }
}

export function toModel(d: ChatbotQuestionDTO): ChatbotQuestion {
  return new ChatbotQuestion(d)
}
