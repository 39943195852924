import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  en: {
    common: require('./en.json'),
  },
  es: {
    common: require('./es.json'),
  },
  fr: {
    common: require('./fr.json'),
  },
  de: {
    common: require('./ger.json'),
  },
}

i18n.use(initReactI18next)

i18n
  .init({
    resources,
    fallbackLng: sessionStorage.getItem('lang') ?? 'es',
    preload: ['es', 'en', 'fr', 'de'],
    defaultNS: 'common',
    fallbackNS: 'common',
    detection: {
      order: ['path'],
    },
    nsSeparator: false,
    keySeparator: false,
  })
  .then()
  .catch((error) => {
    console.error('Error en la inicialización de i18n:', error)
  })

export default i18n
