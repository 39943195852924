import styles from './Chatbot.module.scss'
import { useTranslation } from 'react-i18next'
import { useState, useEffect, useRef } from 'react'
import { Box } from '@mui/material'
import useLoggedInUser from '../../hooks/useLoggedInUser'
import { Permission } from '../../common/enums/Permissions'
import { UserCard } from '../../components/user-card/UserCard'
import { GenericHeader } from '../../components/header/GenericHeader'
import { getChatbotContainer } from '../../container/chatbot-module'
import { ChatbotService } from '../../modules/chatbot/services/ChatbotService'
import { CHATBOT_SERVICE_KEY } from '../../modules/chatbot'
import { toModel } from '../../modules/chatbot/models/ChatbotQuestionDTO'
import { RoleType } from '../../modules/users/enums/RoleEnum'

type ChatbotProps = {
  type: string
}

type Message = {
  from: string
  msg: string
}

const chatbotService =
  getChatbotContainer().get<ChatbotService>(CHATBOT_SERVICE_KEY)

export const Chatbot = (props: ChatbotProps) => {
  const { t } = useTranslation()
  const [messages, setMessages] = useState<Message[]>([])
  const [message, setMessage] = useState<string>('')
  const scrollableDivRef = useRef(null)
  const { user, infoAdditional } = useLoggedInUser()

  const scrollToBottom = () => {
    if (scrollableDivRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const sendMessage = () => {
    const auxMessages = [...messages]
    auxMessages.push({
      msg: message,
      from: 'me',
    })
    setMessages([...auxMessages])
    setMessage('')

    if (!user) return
    const roleTypeValue = parseInt(user.roles)
    const genericSessionID = sessionStorage.getItem('sessionChat') ?? ''
    const languageSession = sessionStorage.getItem('language')

    const question = {
      session_id:
        roleTypeValue === RoleType.Researcher
          ? genericSessionID
          : infoAdditional?.chatbotSessionID ?? '',
      query: message,
      folder: 'default',
      locale: user?.language ?? languageSession ?? 'es',
    }

    chatbotService.makeQuestion(toModel(question)).subscribe((res) => {
      if (!res) return
      if (!res.response) return

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages]
        updatedMessages.push({
          msg: res.response ?? '',
          from: 'chat',
        })
        return updatedMessages
      })

      setMessage('')
    })
  }

  const handlePressKey = (event: {
    key: string
    preventDefault: () => void
  }) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      sendMessage()
    }
  }

  function stripHtmlTags(html: string) {
    const doc = new DOMParser().parseFromString(html, 'text/html')
    return doc.body.innerText || ''
  }

  return (
    <Box className={styles.container}>
      <GenericHeader title={'Chatbot'} />
      <Box className={styles.chatcontainer}>
        <Box className={styles.chatchatbot}>
          <Box className={styles.contentBox}>
            {user?.permissions.includes(Permission.seeGuestSection) && (
              <Box>
                <UserCard />
              </Box>
            )}
            <Box className={styles.chatbox}>
              <Box className={styles.boxScroll} ref={scrollableDivRef}>
                <Box className={styles.patientBox}>
                  <img
                    src={require('../../assets/images/chaticon.png')}
                    width={41}
                    height={41}
                    className={styles.chaticon}
                  />
                  <Box className={styles.patientBox2}>
                    <img
                      src={require('../../assets/images/rectanglechatbot.png')}
                      width={18}
                      height={18}
                    />
                    <Box className={styles.patientboxMsg}>
                      <pre>{t('chatbotMessage')}</pre>
                    </Box>
                  </Box>
                </Box>
                {messages.map((item, i) => (
                  <Box key={item.msg + i}>
                    {item.from === 'chat' ? (
                      <Box className={styles.patientBox}>
                        <img
                          src={require('../../assets/images/chaticon.png')}
                          width={41}
                          height={41}
                          className={styles.chaticon}
                        />
                        <Box className={styles.patientBox2}>
                          <img
                            src={require('../../assets/images/rectanglechatbot.png')}
                            width={18}
                            height={18}
                          />
                          <Box className={styles.patientboxMsg}>
                            <pre>{stripHtmlTags(item.msg)}</pre>
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box className={styles.patientBoxRight}>
                        <Box
                          style={{
                            width: window.innerWidth > 1490 ? '70px' : '121px',
                          }}
                        />
                        <Box className={styles.patientBoxRight2}>
                          <Box className={styles.patientboxMsgRight}>
                            {item.msg}
                          </Box>
                          <img
                            src={require('../../assets/images/rectangleme.png')}
                            width={18}
                            height={18}
                          />
                          <img
                            src={require('../../assets/images/chatmeicon.png')}
                            className={styles.chatmeicon}
                            width={41}
                            height={41}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
              <Box className={styles.boxWrite}>
                <input
                  id="chatinput"
                  onKeyDown={handlePressKey}
                  type="text"
                  className={styles.input}
                  placeholder={t('typeAmessage')}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <img
                  className={styles.img}
                  onClick={sendMessage}
                  src={require('../../assets/images/arrowchat.png')}
                  width={23}
                  height={23}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
