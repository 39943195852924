import React from 'react'
import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts'
import { Box } from '@mui/material'
import styles from './GenericChart.module.css'
import useIsGuestWithoutRegister from '../../hooks/useIsGuestWithoutRegister'
import { useTranslation } from 'react-i18next'

interface DataPoint {
  name: string
  area: number
  bar: number
}

export function GenericChart(): JSX.Element {
  const { isGuestWithoutRegister } = useIsGuestWithoutRegister()
  const { t } = useTranslation()

  const data: DataPoint[] = [
    {
      name: `${t('january').substring(0, 3)} ${new Date().getFullYear()}`,
      area: 500,
      bar: 0,
    },
    {
      name: `${t('february').substring(0, 3)} ${new Date().getFullYear()}`,
      area: 100,
      bar: 20,
    },
    {
      name: `${t('march').substring(0, 3)} ${new Date().getFullYear()}`,
      area: 1500,
      bar: 40,
    },
    {
      name: `${t('april').substring(0, 3)} ${new Date().getFullYear()}`,
      area: 2000,
      bar: 60,
    },
    {
      name: `${t('may').substring(0, 3)} ${new Date().getFullYear()}`,
      area: 2500,
      bar: 80,
    },
    {
      name: `${t('june').substring(0, 3)} ${new Date().getFullYear()}`,
      area: 3000,
      bar: 100,
    },
    {
      name: `${t('july').substring(0, 3)} ${new Date().getFullYear()}`,
      area: 3500,
      bar: 0,
    },
    {
      name: `${t('august').substring(0, 3)} ${new Date().getFullYear()}`,
      area: 4000,
      bar: 20,
    },
  ]
  return (
    <Box
      className={
        isGuestWithoutRegister ? styles.containerGuest : styles.container
      }
    >
      <ResponsiveContainer
        width={isGuestWithoutRegister ? '80%' : '100%'}
        aspect={2}
      >
        <AreaChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, 4000]} />
          <Tooltip />
          <Legend />
          <Area
            type="monotone"
            dataKey="area"
            name={t('areaChart')}
            fill="#01BFD5"
          />
        </AreaChart>
      </ResponsiveContainer>
      <ResponsiveContainer
        width={isGuestWithoutRegister ? '80%' : '100%'}
        aspect={2}
      >
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, 100]} />
          <Tooltip />
          <Legend />
          <Bar dataKey="bar" name={t('barChart')} fill="#01BFD5" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}
