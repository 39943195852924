import { File } from '../models/File'
import { Container, IInit } from '../../../common/container/Container'
import { FileContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { FileDTO, toModel } from '../models/FileDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyList, ItemList } from '../../../common/models/ItemList'

export interface IFileApi extends IInit {
  getByID(id: string | null): Observable<File | undefined>

  getByUserID(id: string | null, q: Query<File>): Observable<ItemList<File>>

  getAllByUserID(id: string | null): Observable<ItemList<File>>

  getFilteredList(q: Query<File>): Observable<ItemList<File>>

  addImage(e: FileDTO, patientID: string): Observable<File | undefined>

  addFile(e: FileDTO): Observable<File | undefined>

  update(e: FileDTO): Observable<File | undefined>

  delete(id: string): Observable<boolean>
}

export class FileApi implements IFileApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService =
      this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as FileContainerConfig).moduleFullUrl
  }

  getByID(id: string | null): Observable<File | undefined> {
    return this._httpClient
      .get<File>({
        url: `${this._url}/` + id,
      })
      .pipe(
        map<FileDTO, File>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        }),
      )
  }

  getByUserID(id: string | null, q: Query<File>): Observable<ItemList<File>> {
    return this._httpClient
      .get<ItemList<File>>({
        url: prepareURL(`${this._url}/byUser/` + id, q),
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<File>())
        }),
      )
  }

  getAllByUserID(id: string | null): Observable<ItemList<File>> {
    return this._httpClient
      .get<ItemList<File>>({ url: `${this._url}/byUserAll/` + id })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<File>())
        }),
      )
  }

  getFilteredList(q: Query<File>): Observable<ItemList<File>> {
    return this._httpClient
      .get<ItemList<File>>({
        url: prepareURL(`${this._url}`, q),
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<File>())
        }),
      )
  }

  addImage(e: FileDTO, patientID: string): Observable<File | undefined> {
    return this._httpClient
      .post<File>({
        url: this._url + '/createImage/' + patientID,
        body: e,
      })
      .pipe(
        map<FileDTO, File>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        }),
      )
  }

  addFile(e: FileDTO): Observable<File | undefined> {
    return this._httpClient
      .post<File>({
        url: this._url,
        body: e,
      })
      .pipe(
        map<FileDTO, File>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        }),
      )
  }

  update(e: FileDTO): Observable<File | undefined> {
    return this._httpClient
      .put<File>({
        url: this._url,
        body: e,
      })
      .pipe(
        map<FileDTO, File>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        }),
      )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient
      .delete({
        url: `${this._url}/` + id,
      })
      .pipe(
        catchError((err) => {
          return of(err)
        }),
      )
  }
}
