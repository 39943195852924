// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PatientList_container__bDpTx {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 39px 160px 0 160px;
  width: 100%;
  height: 100%;
}
.PatientList_container__bDpTx .PatientList_content__n\\+0yY {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: 50px;
  width: 100%;
}

.PatientList_registrationDateText__h5v84 {
  color: var(--TEXT-GRAY-DARK);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/features/patient-list/PatientList.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,2BAAA;EACA,WAAA;EACA,YAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,gBAAA;EACA,WAAA;AACJ;;AAGA;EACE,4BAAA;EACA,6CAAA;EACA,mCAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;AAAF","sourcesContent":[".container {\n  display: flex;\n  align-items: flex-start;\n  flex-direction: column;\n  padding: 39px 160px 0 160px;\n  width: 100%;\n  height: 100%;\n\n  .content {\n    display: flex;\n    flex-direction: column;\n    align-items: baseline;\n    margin-top: 50px;\n    width: 100%;\n  }\n}\n\n.registrationDateText{\n  color: var(--TEXT-GRAY-DARK);\n  font-feature-settings: 'clig' off, 'liga' off;\n  font-family: Montserrat, sans-serif;\n  font-size: 16px;\n  font-weight: bold;\n  line-height: 24px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PatientList_container__bDpTx`,
	"content": `PatientList_content__n+0yY`,
	"registrationDateText": `PatientList_registrationDateText__h5v84`
};
export default ___CSS_LOADER_EXPORT___;
