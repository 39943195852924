import { ChatbotSession } from './ChatbotSession'

export interface ChatbotSessionDTO {
  session_id?: string
  response: string
}

export function emptyFileDTO(): ChatbotSessionDTO {
  return {
    session_id: '',
    response: '',
  }
}

export function fromModel(f: ChatbotSession): ChatbotSessionDTO {
  return {
    session_id: f.sessionID,
    response: f.response,
  }
}

export function toModel(d: ChatbotSessionDTO): ChatbotSession {
  return new ChatbotSession(d)
}
