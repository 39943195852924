import { Container } from '../../common/container/Container'

export type ChatbotContainerConfig = {
  moduleFullUrl: string
}

export type ChatbotProps = {
  parentContainer: Container
  config: ChatbotContainerConfig
}

export const CHATBOT_MODULE = Symbol('CHATBOT_MODULE')

export const CHATBOT_API_KEY = Symbol('CHATBOT_API_KEY')

export const CHATBOT_SERVICE_KEY = Symbol('CHATBOT_SERVICE_KEY')
