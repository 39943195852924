import { ChangeEvent, useRef, useState } from 'react'
import { DocType, FileType } from '../modules/files/enum/FileEnums'
import { v4 as uuidv4 } from 'uuid'
import { FileDTO } from '../modules/files/models/FileDTO'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import useIsRegisteredGuest from './useIsRegisteredGuest'
import { useUser } from '../common/utils/GlobalSelectedUser'
import useLoggedInUser from './useLoggedInUser'

export function useFileUpload(loggedUserID: string) {
  const { t } = useTranslation()
  const [urlFile, setUrlFile] = useState<string | null>(null)
  const [selectedFile, setSelectedFile] = useState<FileDTO | null>(null)
  const [closePreview, setClosePreview] = useState<boolean>(false)
  const [fileChatbot, setFileChatbot] = useState<File>()
  const fileChatbotRef = useRef<File>()
  const { enqueueSnackbar } = useSnackbar()
  const { isRegisteredGuest } = useIsRegisteredGuest()
  const { selectedUser } = useUser()
  const { infoAdditional } = useLoggedInUser()

  const handlePreviewClose = () => {
    setSelectedFile(null)
    setClosePreview(true)
    setUrlFile(null)
  }

  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    // Tu código actual para manejar la selección de archivos
    setClosePreview(false)
    const file = event.target.files?.[0]
    if (!file) {
      setClosePreview(true)
      return
    }
    fileChatbotRef.current = file
    setUrlFile(URL.createObjectURL(file))
    const fileAux = fileChatbotRef.current
    setFileChatbot(fileAux)

    if (
      file?.type === FileType.PDF ||
      file?.type === FileType.Png ||
      file?.type === FileType.Jpg ||
      file?.type === FileType.Docx ||
      file?.type === FileType.Doc
    ) {
      if (file.size > 20971520) {
        enqueueSnackbar(t('imagesMaximumSize'), { variant: 'warning' })
        setClosePreview(true)
        return
      }
    } else {
      enqueueSnackbar(t('fileTypeNotSupported'), { variant: 'warning' })
      setClosePreview(true)
      return
    }

    if (file) {
      const id = uuidv4()

      const reader = new FileReader()
      reader.onload = async (event) => {
        if (event.target) {
          try {
            const base64Data = event.target.result as string
            const base64Portion = base64Data.split(',')[1]
            const myFile: FileDTO = {
              id,
              name: file.name,
              base64: base64Portion,
              type: file.type,
              docType:
                file.type === 'application/pdf'
                  ? DocType.PDF
                  : file?.type === 'image/png' || file?.type === 'image/jpeg'
                  ? DocType.Image
                  : DocType.Doc,
              ownerID: isRegisteredGuest
                ? selectedUser?.id ?? ''
                : loggedUserID || '',
              date: new Date(),
              extension: file.name.split('.').pop() ?? '',
              ownerRole: infoAdditional?.roleName ?? '',
              content: '',
            }
            setSelectedFile(myFile)
          } catch (error) {
            console.error('Error al convertir a base64:', error)
          }
        }
      }
      reader.onerror = (event) => {
        console.error('Error de lectura de archivo:', event.target?.error)
      }

      reader.readAsDataURL(file)
    }
  }

  return {
    urlFile,
    handleFileSelect,
    selectedFile,
    closePreview,
    handlePreviewClose,
    fileChatbotRef,
    fileChatbot,
  }
}
