import { ChatbotQuestionDTO } from './ChatbotQuestionDTO'

export class ChatbotQuestion {
  private readonly _session_id: string | undefined
  private _query: string
  private _folder: string
  private _locale: string

  constructor(p: ChatbotQuestionDTO) {
    this._session_id = p.session_id
    this._query = p.query
    this._folder = p.folder
    this._locale = p.locale
  }

  get sessionID(): string | undefined {
    return this._session_id
  }

  get query(): string {
    return this._query
  }

  set query(value: string) {
    this._query = value
  }

  get folder(): string {
    return this._folder
  }

  set folder(value: string) {
    this._folder = value
  }

  get locale(): string {
    return this._locale
  }

  set locale(value: string) {
    this._locale = value
  }
}
