import { Container, ContainerItem } from '../common/container/Container'
import {
  CHATBOT_API_KEY,
  CHATBOT_SERVICE_KEY,
  CHATBOT_MODULE,
  ChatbotProps,
} from '../modules/chatbot'
import { ChatbotApi } from '../modules/chatbot/api/chatbotApi'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { ChatbotService } from '../modules/chatbot/services/ChatbotService'

let container: Container

function init(p: ChatbotProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(CHATBOT_API_KEY, new ContainerItem({ instance: new ChatbotApi() }))

  items.set(
    CHATBOT_SERVICE_KEY,
    new ContainerItem({
      instance: new ChatbotService({ apiKey: CHATBOT_API_KEY }),
    }),
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getChatbotContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[CHATBOT_MODULE],
      },
    })
  }

  return container
}
