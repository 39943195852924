import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ChatbotSession } from '../models/ChatbotSession'
import { IChatbotApi } from '../api/chatbotApi'
import { ChatbotQuestion } from '../models/ChatbotQuestion'

type Props = {
  apiKey: symbol
}

export interface IChatbotService extends IInit {
  getSessionID(): Observable<ChatbotSession | undefined>
  makeQuestion(
    question: ChatbotQuestion,
  ): Observable<ChatbotQuestion | undefined>
  exampleIomed(): void
}

export class ChatbotService implements IChatbotService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IChatbotApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IChatbotApi>(this._apiKey)
    this._statusService =
      this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getSessionID(): Observable<ChatbotSession | undefined> {
    return this._api.getSessionID()
  }

  makeQuestion(question: ChatbotQuestion): Observable<any | undefined> {
    return this._api.makeQuestion(question)
  }

  exampleIomed() {
    return this._api.exampleIomed()
  }
}
