import { Container, IInit } from '../../../common/container/Container'
import { ChatbotContainerConfig } from '../container'
import { from, Observable, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ChatbotSession } from '../models/ChatbotSession'
import { ChatbotSessionDTO } from '../models/ChatbotSessionDTO'
import { toModel } from '../models/ChatbotSessionDTO'
import { ChatbotQuestion } from '../models/ChatbotQuestion'
import { fromModel } from '../models/ChatbotQuestionDTO'
import axios, { AxiosResponse } from 'axios'

const TOKEN = process.env.REACT_APP_TOKEN_API

export interface IChatbotApi extends IInit {
  getSessionID(): Observable<ChatbotSession | undefined>
  makeQuestion(
    question: ChatbotQuestion,
  ): Observable<ChatbotQuestion | undefined>
  exampleIomed(): void
}

export class ChatbotApi implements IChatbotApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService =
      this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as ChatbotContainerConfig).moduleFullUrl
  }

  getSessionID(): Observable<ChatbotSession | undefined> {
    return from(
      fetch(`${this._url}/Sesiones/iniciar`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: TOKEN ?? '',
        },
      }),
    ).pipe(
      mergeMap(
        (res: Response) => from(res.json()) as Observable<ChatbotSession>,
      ),
      map<ChatbotSessionDTO, ChatbotSession>((data) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(data)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      }),
    )
  }

  makeQuestion(
    question: ChatbotQuestion,
  ): Observable<ChatbotQuestion | undefined> {
    return from(
      axios.post<ChatbotQuestion>(`${this._url}/Chats`, fromModel(question), {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
          Authorization: TOKEN ?? '',
        },
      }),
    ).pipe(
      mergeMap((res: AxiosResponse<ChatbotQuestion>) => {
        return of(res.data)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      }),
    )
  }

  exampleIomed() {
    fetch(`https://radipaem.production.iomed.health/note_span`, {
      method: 'GET',
      headers: {
        'Accept-Profile': 'radipaem@radipaem.es',
        'Content-Profile': 'nlp',
        Authorization: 'YiiKu5a76arDxiLJ',
      },
    })
      .then(async (response) => await response.json())
      .then((json) => console.log(json))
      .catch((err) => console.error('Error:', err))
  }
}
