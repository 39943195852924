// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrivacyCookies_privacyContainer__UVaKH {
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
.PrivacyCookies_privacyContainer__UVaKH .PrivacyCookies_privacyContainer2__AQqHH {
  width: 80%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 20px;
}
.PrivacyCookies_privacyContainer__UVaKH .PrivacyCookies_privacyContainer2__AQqHH .PrivacyCookies_privacyTitle__oyBGz {
  color: var(--BLUE-ARIA);
  font-family: "GothamBold", sans-serif;
  font-size: 42px;
  text-align: left;
  margin-top: 4%;
}
.PrivacyCookies_privacyContainer__UVaKH .PrivacyCookies_privacyContainer2__AQqHH .PrivacyCookies_linedashboard__rJSay {
  height: 3.5px;
  width: 90px;
  border-radius: 100px;
  background-color: var(--GREEN-ARIA);
  margin-top: 0.8%;
}
.PrivacyCookies_privacyContainer__UVaKH .PrivacyCookies_textbox__moat1 {
  display: flex;
  flex-direction: column;
  margin-top: 2.5%;
}
.PrivacyCookies_privacyContainer__UVaKH .PrivacyCookies_textbox__moat1 .PrivacyCookies_title__REOzf {
  font-family: "GothamBold", sans-serif;
  margin-top: 2%;
  color: var(--TEXT-GRAY-DARK);
}
.PrivacyCookies_privacyContainer__UVaKH .PrivacyCookies_textbox__moat1 .PrivacyCookies_description__5Y9UB {
  margin-top: 0.5%;
  color: var(--TEXT-GRAY-DARK);
  white-space: pre-wrap;
  font-family: "GothamLight", sans-serif;
  text-align: justify;
}
.PrivacyCookies_privacyContainer__UVaKH .PrivacyCookies_textbox__moat1 .PrivacyCookies_lastUpdate__-CZYZ {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/features/privacyCookies/PrivacyCookies.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,eAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,UAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,mBAAA;AACJ;AACI;EACE,uBAAA;EACA,qCAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AACN;AAEI;EACE,aAAA;EACA,WAAA;EACA,oBAAA;EACA,mCAAA;EACA,gBAAA;AAAN;AAIE;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AAFJ;AAII;EACE,qCAAA;EACA,cAAA;EACA,4BAAA;AAFN;AAKI;EACE,gBAAA;EACA,4BAAA;EACA,qBAAA;EACE,sCAAA;EACF,mBAAA;AAHN;AAMI;EACE,aAAA;EACE,sBAAA;EACF,uBAAA;EACA,mBAAA;AAJN","sourcesContent":[".privacyContainer {\n  display: flex;\n  flex: 1;\n  flex-wrap: wrap;\n  flex-direction: row;\n  justify-content: center;\n\n  .privacyContainer2 {\n    width: 80%;\n    min-width: 200px;\n    display: flex;\n    flex-direction: column;\n    align-self: center;\n    margin-bottom:20px;\n\n    .privacyTitle {\n      color: var(--BLUE-ARIA);\n      font-family: 'GothamBold', sans-serif;\n      font-size: 42px;\n      text-align: left;\n      margin-top: 4%;\n    }\n\n    .linedashboard {\n      height: 3.5px;\n      width: 90px;\n      border-radius: 100px;\n      background-color: var(--GREEN-ARIA);\n      margin-top: 0.8%;\n    }\n  }\n\n  .textbox {\n    display: flex;\n    flex-direction: column;\n    margin-top: 2.5%;\n\n    .title {\n      font-family: 'GothamBold', sans-serif;\n      margin-top: 2%;\n      color: var(--TEXT-GRAY-DARK);\n    }\n\n    .description {\n      margin-top: 0.5%;\n      color: var(--TEXT-GRAY-DARK);\n      white-space: pre-wrap;\n        font-family: 'GothamLight', sans-serif;\n      text-align: justify;\n    }\n\n    .lastUpdate{\n      display: flex;\n        flex-direction: column;\n      justify-content: center;\n      align-items: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"privacyContainer": `PrivacyCookies_privacyContainer__UVaKH`,
	"privacyContainer2": `PrivacyCookies_privacyContainer2__AQqHH`,
	"privacyTitle": `PrivacyCookies_privacyTitle__oyBGz`,
	"linedashboard": `PrivacyCookies_linedashboard__rJSay`,
	"textbox": `PrivacyCookies_textbox__moat1`,
	"title": `PrivacyCookies_title__REOzf`,
	"description": `PrivacyCookies_description__5Y9UB`,
	"lastUpdate": `PrivacyCookies_lastUpdate__-CZYZ`
};
export default ___CSS_LOADER_EXPORT___;
