import {
  toModel as userToModel,
  User,
  UserDTO,
  UserQuery,
  RegisterUser,
  ChangePassword,
  SendSMS,
  UserChatbotSession,
} from '../models/User'
import { Container, IInit } from '../../../common/container/Container'
import { UserContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { AuthDTO } from '../models/AuthDTO'
import { LoggedUserDTO } from '../models/LoggedUserDTO'
import { prepareURL } from '../../../common/api/http-helpers'
import { STATUS_SERVICE_KEY } from '../../../container/app'

export interface IUserApi extends IInit {
  getByID(id: string): Observable<User | undefined>

  getFilteredList(q: Query<UserQuery>): Observable<ItemList<User>>

  add(e: RegisterUser): Observable<User | string | undefined>

  update(e: RegisterUser): Observable<RegisterUser | undefined>

  updateLanguage(e: UserDTO): Observable<UserDTO | undefined>

  updateChatbotSession(
    e: UserChatbotSession,
  ): Observable<UserChatbotSession | undefined>

  login(a: AuthDTO): Observable<LoggedUserDTO | undefined>

  logout(): Observable<boolean>

  loginGuest(code: string): Observable<LoggedUserDTO | undefined>

  delete(id: string): Observable<boolean>

  sendRecoverPassword(email: string, lang: string): Observable<any>

  changePassword(cp: ChangePassword): Observable<any>

  sendInvitationCode(
    phone: string,
    emailFrom: string,
  ): Observable<SendSMS | undefined>

  addInvitationCode(code: string, id: string): Observable<any>
  getUsersInvitationsByUserID(q: Query<UserQuery>): Observable<ItemList<User>>
}

export class UserApi implements IUserApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService =
      this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as UserContainerConfig).moduleFullUrl
  }

  add(e: RegisterUser): Observable<User | string | undefined> {
    return this._httpClient
      .post<User>({ url: `${this._url}/register`, body: e })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        }),
      )
  }

  getByID(id: string): Observable<User | undefined> {
    return this._httpClient.get<User>({ url: `${this._url}/${id}` }).pipe(
      map<UserDTO, User>((dto) => userToModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      }),
    )
  }

  sendInvitationCode(
    phone: string,
    emailFrom: string,
  ): Observable<SendSMS | undefined> {
    return this._httpClient
      .post<SendSMS>({
        url: `${this._url}/invitationcode`,
        body: {
          phone,
          emailFrom,
        },
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        }),
      )
  }

  getFilteredList(q: Query<UserQuery>): Observable<ItemList<User>> {
    return this._httpClient
      .get<ItemList<User>>({ url: prepareURL(`${this._url}`, q) })
      .pipe(
        map<ItemList<UserDTO>, ItemList<User>>((dto) => {
          const itemList = emptyList<User>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => userToModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<User>())
        }),
      )
  }

  update(e: RegisterUser): Observable<RegisterUser | undefined> {
    return this._httpClient
      .put<RegisterUser>({ url: `${this._url}/${e.id}`, body: e })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        }),
      )
  }

  login(a: AuthDTO): Observable<LoggedUserDTO | undefined> {
    return this._httpClient
      .post<LoggedUserDTO>({ url: `${this._url}/loginWeb`, body: a })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        }),
      )
  }

  loginGuest(code: string): Observable<LoggedUserDTO | undefined> {
    return this._httpClient
      .post<LoggedUserDTO>({
        url: `${this._url}/loginGuest`,
        body: { code },
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        }),
      )
  }

  logout(): Observable<boolean> {
    return this._httpClient.post<boolean>({ url: `${this._url}/logout` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      }),
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      }),
    )
  }

  sendRecoverPassword(email: string, lang: string): Observable<any> {
    return this._httpClient
      .post<any>({
        url: `${this._url}/recoverPassword`,
        body: { email, lang },
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        }),
      )
  }

  changePassword(cp: ChangePassword): Observable<any> {
    return this._httpClient
      .post<any>({ url: `${this._url}/changePassword`, body: cp })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        }),
      )
  }

  addInvitationCode(code: string, id: string): Observable<any> {
    return this._httpClient
      .post<any>({
        url: `${this._url}/addInvitationCode`,
        body: {
          code,
          id,
        },
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        }),
      )
  }

  getUsersInvitationsByUserID(q: Query<UserQuery>): Observable<ItemList<User>> {
    return this._httpClient
      .get<ItemList<User>>({
        url: prepareURL(`${this._url}/userInvitationList`, q),
      })
      .pipe(
        map<ItemList<UserDTO>, ItemList<User>>((dto) => {
          const itemList = emptyList<User>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => userToModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<User>())
        }),
      )
  }

  updateChatbotSession(
    e: UserChatbotSession,
  ): Observable<UserChatbotSession | undefined> {
    return this._httpClient
      .put<UserChatbotSession>({
        url: `${this._url}/update/chatSession`,
        body: e,
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        }),
      )
  }

  updateLanguage(e: UserDTO): Observable<UserDTO | undefined> {
    return this._httpClient
      .put<UserDTO>({
        url: `${this._url}/update/language`,
        body: e,
      })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        }),
      )
  }
}
