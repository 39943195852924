import { useState, useEffect } from 'react'
import { getUserContainer } from '../container/user-module'
import { LoggedUserService } from '../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../modules/users'
import { LoggedUser } from '../modules/users/models/LoggedUser'
import { UserService } from '../modules/users/services/UserService'

const loggedUserService = getUserContainer().get<LoggedUserService>(
  LOGGED_USER_SERVICE_KEY,
)
const userService = getUserContainer().get<UserService>(USER_SERVICE_KEY)
interface InfoAdditional {
  dni: string
  createdAt: Date
  roleName: string
  chatbotSessionID: string
}
const useLoggedInUser = () => {
  const [user, setUser] = useState<LoggedUser | undefined>(
    loggedUserService.get(),
  )
  const [infoAdditional, setInfoAdditional] = useState<InfoAdditional>()

  useEffect(() => {
    loggedUserService.getObservable().subscribe((u: LoggedUser | undefined) => {
      setUser(u)
    })
  }, [])

  useEffect(() => {
    if (!user) return
    userService.getByID(user.id).subscribe((res) => {
      res &&
        setInfoAdditional({
          dni: res.idDni,
          createdAt: res.createdAt,
          roleName: res.roleName,
          chatbotSessionID: res.chatbotSessionID,
        })
    })
  }, [user])

  return {
    user,
    infoAdditional,
  }
}

export default useLoggedInUser
