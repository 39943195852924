import styles from './LoginRegister.module.scss'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { getUserContainer } from '../../container/user-module'
import { type LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { type AuthDTO } from '../../modules/users/models/AuthDTO'
import { useSnackbar } from 'notistack'
import {
  emptyRegisterUser,
  type RegisterUser,
  UserChatbotSession,
} from '../../modules/users/models/User'
import { type UserService } from '../../modules/users/services/UserService'
import {
  URL_DASHBOARD,
  URL_DASHBOARD_RESEARCHER,
  URL_PATIENT_LIST,
} from '../../routes/routes-constants'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, IconButton, Modal } from '@mui/material'
import Select from 'react-select'
import { RoleType, roleTypes } from '../../modules/users/enums/RoleEnum'
import i18n from '../../i18n/i18n'
import playStore from '../../assets/home-view/playStore.svg'
import appleStore from '../../assets/home-view/appleStore.svg'
import arrowHome from '../../assets/home-view/arrowHome.svg'
import { CustomModal } from '../modal/CustomModal'
import { getChatbotContainer } from '../../container/chatbot-module'
import { CHATBOT_SERVICE_KEY } from '../../modules/chatbot'
import { ChatbotService } from '../../modules/chatbot/services/ChatbotService'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import useLoggedInUser from '../../hooks/useLoggedInUser'

export interface LoginRegisterProps {
  type: string
}
interface SelectStyles {
  control: (
    styles: Record<string, any>,
    state: { isFocused: boolean },
  ) => Record<string, any>
  option: (
    styles: Record<string, any>,
    state: { isFocused: boolean; isSelected: boolean },
  ) => Record<string, any>
  dropdownIndicator: (base: any) => Record<string, any>
}
const loggedUserService = getUserContainer().get<LoggedUserService>(
  LOGGED_USER_SERVICE_KEY,
)
const userService = getUserContainer().get<UserService>(USER_SERVICE_KEY)
const chatbotService =
  getChatbotContainer().get<ChatbotService>(CHATBOT_SERVICE_KEY)

export const LoginRegister = (props: LoginRegisterProps) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()
  const pathname = location.pathname
  const initialValue =
    pathname === `/register/${roleTypes()[RoleType.Researcher]}`
      ? RoleType.Researcher
      : RoleType.Patient

  const [selectedOption, setSelectedOption] = useState({
    value: initialValue,
    label: roleTypes()[initialValue],
  })
  const [active, setActive] = useState<string>(props.type)
  const [emailPassword, setEmailPassword] = useState<string>('')
  const [login, setLogin] = useState<AuthDTO>({
    login: '',
    password: '',
  })
  const [registerUser, setRegisterUser] = useState<RegisterUser>({
    ...emptyRegisterUser(i18n.language),
    role: selectedOption.value,
    roleName: selectedOption.label,
  })
  const [termsAgreed, setTermsAgreed] = useState<boolean>(false)
  const [mpasswordOpened, setMPasswordOpened] = useState<boolean>(false)
  const [selectedRole, setSelectedRole] = useState<string>('')
  const [showPassword, setShowPassword] = useState(false)
  const navigate = useNavigate()
  const { user } = useLoggedInUser()

  const options = [
    { value: Number(RoleType.Patient), label: roleTypes()[RoleType.Patient] },
    {
      value: Number(RoleType.Researcher),
      label: roleTypes()[RoleType.Researcher],
    },
  ]

  const optionsInvited = [
    { value: Number(RoleType.Patient), label: t('doctor') },
    { value: Number(RoleType.Patient), label: t('familiar') },
  ]

  const handlePressKey = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      active === 'login' ? handleLogin() : handleRegister()
    }
  }

  const handleSelectedRole = (e: any) => {
    setSelectedRole(e.label)
    setRegisterUser(
      Object.assign(
        { ...registerUser },
        { role: Number(RoleType.Patient), roleName: e.label },
      ),
    )
  }

  const handleLogin = () => {
    loggedUserService.login(login).subscribe((res) => {
      if (!res) {
        enqueueSnackbar(t('incorrectUserOrPassword'), { variant: 'error' })
        setLogin({
          login: '',
          password: '',
        })
        return
      }
      const roleDashboard: string[] = [
        '',
        URL_DASHBOARD,
        '',
        URL_DASHBOARD_RESEARCHER,
        URL_PATIENT_LIST,
      ]

      const roleTypeValue = parseInt(res.roles)

      chatbotService.getSessionID().subscribe((chatbotRes) => {
        if (
          roleTypeValue === RoleType.PatientGuest ||
          roleTypeValue === RoleType.Researcher
        ) {
          return
        }
        if (chatbotRes?.sessionID) {
          const chatbotSession: UserChatbotSession = {
            userID: res.id,
            chatbotSessionID: chatbotRes.sessionID,
          }
          userService.updateChatbotSession(chatbotSession).subscribe()
        }
      })

      if (roleTypeValue >= 1 && roleTypeValue <= 4) {
        navigate(roleDashboard[roleTypeValue])
      }
    })
  }

  const handleRegister = () => {
    if (
      !registerUser?.firstName ||
      !registerUser?.lastName ||
      !registerUser.idDNI ||
      !registerUser.email ||
      !registerUser.password ||
      !termsAgreed
    ) {
      enqueueSnackbar(t('allFieldsAreRequired'), { variant: 'warning' })
      return
    }

    userService.add(registerUser).subscribe((res) => {
      if (!res)
        return enqueueSnackbar(t('anErrorHasOcurred'), { variant: 'error' })

      if (typeof res === 'string') {
        return enqueueSnackbar(t(res), { variant: 'error' })
      } else {
        enqueueSnackbar(t('correctlyRegisteredUser'), { variant: 'success' })
        setActive('login')
      }
      setRegisterUser(emptyRegisterUser(i18n.language))
    })
  }

  const handleChangeRegister = (e: any) => {
    setRegisterUser(
      Object.assign({ ...registerUser }, { [e.target.name]: e.target.value }),
    )
  }

  const handleSendRememberPassword = () => {
    // TODO add logic for translation email, add lang to localstorage
    const lang = user?.language ?? sessionStorage.getItem('language') ?? 'en'
    loggedUserService
      .sendRecoverPassword(emailPassword, lang)
      .subscribe((res) => {
        if (res !== undefined) {
          enqueueSnackbar(t('anErrorHasOcurred'), { variant: 'error' })
        } else {
          enqueueSnackbar(t('emailSuccesfullySent'), { variant: 'success' })
        }
        setEmailPassword('')
        setMPasswordOpened(false)
      })
  }

  const selectStyle: SelectStyles = {
    control: (base, { isFocused }) => ({
      ...base,
      '&:hover': {
        border: 'solid 1px var(--GREEN-ARIA)',
        boxShadow: 'none',
      },
      border: 'solid 1px var(--GREEN-ARIA)',
      boxShadow: 'none',
      borderRadius: '18px',
      marginTop: '4%',
      textAlign: 'left',
    }),
    option: (base, { isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? 'var(--GREEN-ARIA)' : 'white',
        color: isFocused ? 'white' : 'black',
        boxShadow: 'none',
      }
    },
    dropdownIndicator: (base: any) => ({
      ...base,
      color: 'var(--GREEN-ARIA)',
      textAlign: 'left',
    }),
  }

  useEffect(() => {
    const code = window.location.pathname.substring(
      window.location.pathname.lastIndexOf('/') + 1,
    )
    !Number.isNaN(Number(code)) &&
      setRegisterUser(
        Object.assign(
          { ...registerUser },
          { role: Number(RoleType.Patient), invitationCode: code },
        ),
      )
  }, [])

  return (
    <Box className={styles.loginContainer}>
      <Box className={styles.downloadApp}>
        {active === 'register' ? (
          <p className={styles.title0}>{t('creatAccount')}</p>
        ) : (
          <p className={styles.title0}>{t('patientLogin')}</p>
        )}
        <Box className={styles.line0}>
          <Box className={styles.line} />
        </Box>
        <img src={arrowHome} className={styles.arrow} />
        <Box className={styles.downloadBox}>
          <p className={styles.title}>{t('downloadAriaAppNow')}</p>
          <p className={styles.description}>
            {t('downloadAriaAppDescription')}
          </p>
          <Box className={styles.stores}>
            <img src={playStore} />
            <img src={appleStore} />
          </Box>
        </Box>
      </Box>

      <Box className={styles.login}>
        <Box className={styles.tabs}>
          <Box
            className={styles.createAccount}
            onClick={() => {
              setActive('register')
            }}
          >
            <p className={active === 'register' ? styles.active : ''}>
              {t('createAccount')}
            </p>
          </Box>
          <Box
            className={styles.login2}
            onClick={() => {
              setActive('login')
            }}
          >
            <p className={active === 'login' ? styles.active : ''}>
              {t('login')}
            </p>
          </Box>
        </Box>

        {active === 'register' ? (
          <Box className={styles.registerBox}>
            <p className={styles.title}>{t('signUpForaFreeAccount')}</p>
            <Box className={styles.form}>
              <input
                onKeyDown={handlePressKey}
                type="text"
                name="firstName"
                className={styles.input}
                placeholder={t('name')}
                value={registerUser?.firstName || ''}
                onChange={handleChangeRegister}
              />
              <input
                onKeyDown={handlePressKey}
                type="text"
                name="lastName"
                className={styles.input}
                placeholder={t('lastName')}
                value={registerUser?.lastName || ''}
                onChange={handleChangeRegister}
              />
              <input
                onKeyDown={handlePressKey}
                type="text"
                name="idDNI"
                className={styles.input}
                placeholder={t('ID')}
                value={registerUser?.idDNI || ''}
                onChange={handleChangeRegister}
              />
              <input
                onKeyDown={handlePressKey}
                type="email"
                name="email"
                className={styles.input}
                placeholder={t('email')}
                value={registerUser?.email || ''}
                onChange={handleChangeRegister}
              />
              <input
                onKeyDown={handlePressKey}
                type="password"
                name="password"
                className={styles.input}
                placeholder={t('password')}
                value={registerUser?.password || ''}
                onChange={handleChangeRegister}
              />

              <div className={styles.invitationCode}>
                {registerUser?.invitationCode ? (
                  <>
                    <Select
                      options={optionsInvited}
                      styles={selectStyle}
                      value={{
                        value: registerUser?.role,
                        label: selectedRole,
                      }}
                      onChange={(v) => handleSelectedRole(v)}
                    />
                  </>
                ) : (
                  <>
                    <Select
                      options={options}
                      styles={selectStyle}
                      placeholder={t('role')}
                      value={selectedOption}
                      onChange={(v) => {
                        if (v) {
                          setRegisterUser({
                            ...registerUser,
                            role: Number(v.value),
                            roleName: v.label,
                          })
                          setSelectedOption(v)
                        }
                      }}
                    />
                  </>
                )}
              </div>
              <Box className={styles.agreeDiv}>
                <Box className={styles.round}>
                  <input
                    type="checkbox"
                    id="checkbox"
                    checked={termsAgreed}
                    onChange={() => {
                      setTermsAgreed(!termsAgreed)
                    }}
                  />
                  <label htmlFor="checkbox"></label>
                </Box>
                <p className={styles.agreeTerms}>{t('agreeTerms')}</p>
              </Box>
              <Box className={styles.registerButton} onClick={handleRegister}>
                {t('createAnAccount')}
              </Box>
              <Box className={styles.container}></Box>
            </Box>
          </Box>
        ) : (
          <Box className={styles.loginBox}>
            <p className={styles.title}>{t('loginToYourAccount')}</p>
            <Box className={styles.form}>
              <input
                onKeyDown={handlePressKey}
                type="text"
                name="name"
                value={login.login || ''}
                className={styles.input}
                placeholder={t('email')}
                onChange={(v) => {
                  setLogin(
                    Object.assign({ ...login }, { login: v.target.value }),
                  )
                }}
              />
              <div className={styles.input}>
                <input
                  onKeyDown={handlePressKey}
                  type={showPassword ? 'text' : 'password'}
                  name="name"
                  value={login.password || ''}
                  className={styles.input2}
                  placeholder={t('password')}
                  onChange={(v) => {
                    setLogin(
                      Object.assign({ ...login }, { password: v.target.value }),
                    )
                  }}
                />
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </div>
              <p
                className={styles.forgotPassword}
                onClick={() => {
                  setMPasswordOpened(true)
                }}
              >
                {t('forgotPassword')}
              </p>
              <Box className={styles.registerButton} onClick={handleLogin}>
                {t('login')}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Modal
        open={mpasswordOpened}
        onClose={() => {
          setMPasswordOpened(false)
        }}
      >
        <>
          <CustomModal
            title={''}
            handleClose={() => {
              setMPasswordOpened(false)
            }}
          >
            <Box className={styles.modalBox}>
              <h2 className={styles.text}>{t('rememberPassword')}</h2>
            </Box>

            <Box className={styles.inputbox}>
              <input
                className={styles.input}
                type="text"
                name="firstName"
                placeholder={t('email')}
                value={emailPassword}
                onChange={(e) => {
                  setEmailPassword(e.target.value)
                }}
              />
              <Box
                className={styles.button}
                onClick={handleSendRememberPassword}
              >
                {t('send')}
              </Box>
            </Box>
          </CustomModal>
        </>
      </Modal>
    </Box>
  )
}
